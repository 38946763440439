body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'CardFont';
  src: local('CardFont'), url(./Fonts/card.woff2) format('woff2');
}