body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'CardFont';
  src: local('CardFont'), url(/static/media/card.8cad3a10.woff2) format('woff2');
}
.card {
  -webkit-perspective: 1000px;
          perspective: 1000px;
  z-index: 5; }
  .card.flipped {
    z-index: reset; }

.flipped .flipper {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }

.flipper {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative; }

.back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  box-shadow: 0 0 1px #888888; }
  .back img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.front {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg); }
  .front img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 1px 1px 2px #888888;
    border-color: whitesmoke;
    border-style: solid;
    border-width: 3px 3px 23px 3px; }
  .front h2 {
    font-family: 'CardFont';
    text-align: center;
    padding: 1px;
    position: absolute;
    margin: -1px;
    box-sizing: border-box;
    bottom: 0;
    right: 0;
    left: 0; }

.cardbuttons {
  -webkit-transition: 0.6s;
  transition: 0.6s;
  left: 0;
  position: absolute;
  text-align: center; }
  .cardbuttons .undo {
    position: absolute;
    left: 0; }

.hand-controls {
  -webkit-transition: 0.6s;
  transition: 0.6s; }
  .hand-controls * {
    -webkit-transition: 0.6s;
    transition: 0.6s; }

