.card {
    perspective: 1000px;
    z-index: 5;
    
    &.flipped {
        z-index: reset;
    }
}

.flipped .flipper {
    transform: rotateY(180deg);
}

.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
    position: relative;
}

.back {
    backface-visibility: hidden;
    position: absolute;
    width : 100%;
    height : 100%;
    transform: rotateY(180deg);
    box-shadow: 0 0 1px #888888;
    
    img {
        position: absolute;
        top : 0;
        left : 0;
        width : 100%;
        height: 100%;
    }
}

.front {
    backface-visibility: hidden;
    position: absolute;
    width : 100%;
    height : 100%;
    z-index: 2;
    transform: rotateY(0deg);

    img {
        display : block;
        margin: auto;
        
        max-width : 100%;
        max-height: 100%;

        box-shadow: 1px 1px 2px #888888;

        border-color : whitesmoke;
        border-style: solid;
        border-width : 3px 3px 23px 3px;
    }

        
    h2 {
        font-family: 'CardFont';              
        text-align : center;
        padding : 1px;
        position : absolute;
        
        margin : -1px;
        
        box-sizing: border-box;
        bottom: 0;
        right : 0;
        left : 0;
    }
    
}

.cardbuttons {
    transition: 0.6s;
    left :0;
    position: absolute;

    text-align : center;

    .undo {
        position: absolute;
        left : 0;
    }    
}